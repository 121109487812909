import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que inicialment té forma de copa, però quan és madur és més o manco ondulat i amb plecs. La superfici interna està formada per l’himeni i de color violaci, i l’externa, també violàcia de jove, torna grisenca quan el bolet és madur. Les espores són el·líptiques, llises, amb dues gotes lipídiques als extrems que desapareixen a la maduresa, de 13-16 x 7-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      